import React from "react";
import Styled from './NewInfoButton.module.css'

const NewInfoButton = () => {
    return (
        <a href="img/notices/Final_List_02_11_2023.pdf" target="_blank">
            <div className={`${Styled.circle}`} style={{textAlign:'center'}}>
            <b>Final Merit List of "Medal Lao Naukri Pao"</b>
            </div>
        </a>

    );
}

export default NewInfoButton