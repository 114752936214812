import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../styles/Gift.module.css";
import api from "../utils/ApiMethod";
import { Button, useMediaQuery } from "@chakra-ui/react";
import "swiper/css/navigation";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import gallerystyles from "../styles/GalleryThree.module.css";

import { Navigation } from "swiper/modules";

function GalleryCarousal() {
  const [isSmallScreen] = useMediaQuery("(max-width: 990px)");
  const [data, setData] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/api/gallery");
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const slidesPerView = isSmallScreen ? 1 : 1;

  return (
    <>
      <div
        className={gallerystyles.gallerycontainer}
        style={{
          paddingBottom: "10px",
          //   border: "2px solid #fcb606",
        }}
      >
        <div className={gallerystyles.head}>
          <a href="/pressgallery" title="Photo Gallery">
            <Button colorScheme="yellow" size="lg" fontSize="16" py={8}>
              Photo Gallery ⨠
            </Button>
          </a>
        </div>

        <Swiper
          autoplay={{
            delay: 2000,
          }}
          // autoplay={{
          //   delay: 2000, // Autoplay delay in milliseconds
          //   disableOnInteraction: true, // Autoplay stops even after user interaction
          // }}
          slidesPerView={slidesPerView}
          // rewind={true}
          navigation={true}
          modules={[Navigation]}
          loop={true}
          spaceBetween={10}
          bgGradient={[
            "linear(to right,  rgba(255,255,255,1),rgba(255,255,58,0))",
          ]}
          className={styles.mySwiper}
        >
          {data.map((elem, index) => (
            <SwiperSlide key={index}>
              <div className="swiper-slide zoom">
                <div className="whatsNewCarousel__card">
                  <div
                    className="award__img"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <img
                      src={`https://api.biharsports.org/${elem?.imageUrl?.replace(
                        "public/images/",
                        "images/"
                      )}`}
                      alt="Chandan"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
export default GalleryCarousal;
