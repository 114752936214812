import React from "react";
import HeaderTop from "./HeaderTop";
import { useState } from "react";
import { useEffect } from "react";
import api from "../utils/ApiMethod";
import Menu from "./Menu";
import { background, border } from "@chakra-ui/react";
import Styled from '../pages/NewinfoButton/NewInfoButton.module.css'
import NewInfoButton from "../pages/NewinfoButton/NewInfoButton";


const Header = () => {
  const [navData, setNavData] = useState({});
  const [navSlogan, setNavSlogan] = useState();
  const [fullSlogan, setFullSlogan] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("get/header");
      setNavData(data[0]);
      setNavSlogan(data[0]?.slogan);
      if (data[0]?.slogan.split(" ").length > 2) {
        setFullSlogan(data[0].slogan.split(" "));
      } else {
        setNavSlogan(data[0]?.slogan);
      }

      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <HeaderTop />
      {/* <Navbar /> */}
      <div className={`sticky_header ${isScrolled ? "scrolled" : ""}`}>
        <div className="header" style={{ border: "" }}>
          <div className="siteheader">

          <div className={`${Styled.ghghgh}`}>
            <NewInfoButton />
            </div>

            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-7">
                  <a href="/" className="sitelogo">
                    <img
                      src="./img/bssalogo73.png"
                      // className="img-fluid"
                      alt="bssa-logo"
                    />
                    <h1 style={{ color: "#016938" }}>
                      {fullSlogan.length > 0 ? fullSlogan[0] : navSlogan}
                      <span style={{ color: "#f05623" }}>
                        {" "}
                        {fullSlogan.length > 1 ? fullSlogan[1] : ""}{" "}
                        {fullSlogan.length > 2 ? fullSlogan[2] : ""}
                      </span>{" "}
                      {fullSlogan.length >= 3 ? fullSlogan[3] : ""}
                      <small style={{ color: "#016938", fontweight: "600" }}>
                        {navData?.state}
                      </small>
                    </h1>
                    {/* #f05623 */}
                  </a>
                </div>

                <div className="col-lg-7 col-md-7 col-sm-5">
                  <div
                    className="location__naveen"
                    style={{
                      display: "flex",
                      // gap: "1px",
                    }}
                  >
                    <div
                      className="location__link"
                      // style={{ border: "2px solid red" }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: " 30%",
                          // height: "40px",
                          // border: "2px solid red",
                        }}
                      >
                        <a
                          href="https://online.bih.nic.in/GADREC/(S(uciq31wvsnuj5h0syzxylfsp))/Default.aspx"
                          target="_blank"
                          rel="noreferrer"
                          className="bg-headcont payplay__link"
                          style={{
                            width: "150px",
                            height: "50px",
                            paddingRight: "55%",
                          }}
                        >
                          <img
                            src={`https://api.biharsports.org/${navData?.buttonImage?.replace(
                              "public/images/",
                              "images/"
                            )}`}
                            alt="pay & play logo"
                            className="payplay_img"
                          />
                          <div>
                            <span
                              className="play"
                              // style={{ fontWeight: "bold" }}
                            >
                              Medal Lao
                            </span>

                            <span className="logo__title medalbtn">
                              {" "}
                              {/* <h6 style={{ fontSize: "14px", color: "blue" }}> */}
                              Naukri Pao
                              {/* </h6> */}
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="naveen1">
                      <div className="cmProfile">
                        <div className="cmProfile__textarea">
                          <h4 className="cmProfile__text">
                            {navData?.profileName}
                          </h4>
                          <small className="">{navData?.designation}</small>
                        </div>
                        <div className="cmProfile__img">
                          <img
                            src={`https://api.biharsports.org/${navData?.cmImage?.replace(
                              "public/images/",
                              "images/"
                            )}`}
                            alt="cm-image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Menu />
      </div>
    </>
  );
};
export default Header;
