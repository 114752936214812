import { SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import styled from "./style.module.css";
import Container from "react-bootstrap/Container";
import api from "../../utils/ApiMethod";
import downloadIcon from "../../assets/images/Download.jpeg";
import Styles from "../../styles/Tender.module.css";
import Breadcrumb from "../../components/common/Breadcrumb";
const Tenders = () => {
  const [data, setData] = useState([]);

  const [search, setSearch] = useState("");

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/api/tender");
      setData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div>
      <Breadcrumb
        pageName="Tender"
        pageTitle="Tender"
        id="#Tender"
        // img_url="./img/Sports-recruitment-Policy-page-desktop.jpg"
        // mobile_banner="./img/Sports-recruitment-Policy-page-mobile.jpg"

        img_url={`https://api.biharsports.org/${data[0]?.bannerUrl?.replace(
          "public/images/",
          "images/"
        )}`}
        mobile_banner={`https://api.biharsports.org/${data[0]?.mobileBannerUrl?.replace(
          "public/images/",
          "images/"
        )}`}
      />
    <div className={styled.Container}>



      <h1 className="pt-2" style={{ marginBottom: "10px" }}>
        {/* निविदाएं */}
        {data[0]?.text}
      </h1>
      {/* input field for search the item */}
      <div class={styled.input_group}>
        <div class="form-outline">
          <input
            type="search"
            id="form1"
            class="form-control"
            placeholder="Search for tender"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <table class="table table-striped ">
        <thead>
          <tr class="table-primary">
            <th className={`${Styles.th}`} scope="col">
              Sl.No.{" "}
            </th>
            <th className={`${Styles.head}`} scope="col">
              Subject
            </th>
            <th className={`${Styles.head}`} scope="col">
              {" "}
              Date
            </th>
            <th className={`${Styles.head}`} scope="col">
              Expire date
            </th>
            <th className={`${Styles.head}`} scope="col">
              Download
            </th>
          </tr>
        </thead>
        <tbody>
          {data[0]?.tenders?.filter((item) =>
              item?.tenderName?.toLowerCase().includes(search.toLowerCase()) &&  item.isDeleted === false
            )
            .map((item, i) => (
              <tr>
                <th scope="row">{i + 1}</th>
                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  {item.tenderName}
                </td>
                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  {item.openingDate}
                </td>

                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  {item.lastDate}
                </td>

                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  {
                    item?.image ?  <a href={`https://api.biharsports.org/${item?.image?.replace(
                      "public/images/",
                      "images/"
                    )}`} target="_blank">
                                <img className={Styles.table_image} src={downloadIcon} />
                              </a> :  <a href= {item?.url} target="_blank">
                    <img className={Styles.table_image} src={downloadIcon} />
                  </a>
                  }

                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
    </div>
  );
};

export default Tenders;
