import { GridItem, SimpleGrid, Box, background } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import api from "../../utils/ApiMethod";
const OrganizationalStru = () => {
  const [commingData, setCommingData] = useState([]);

  const handleFetchData = async () => {
    try {
      // const data = await api.fetchData("/get/organizationalstructure");
      const data = await api.fetchData("/api/orgstructure"); // url for db.json
      setCommingData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div>
      <Breadcrumb
        pageName="Organizational Structure"
        pageTitle="Organizational Structure"
        id="#organizational-structure"
        img_url={`https://api.biharsports.org/${commingData[0]?.bannerUrl.replace(
          "public/images/",
          "images/"
        )}`}
        // img_url={`http://localhost:5000/${commingData[0]?.bannerUrl.replace(
        //   "public/images/",
        //   "images/"
        // )}`}
        // mobile_banner={`http://localhost:5000/${commingData[0]?.mobileBannerUrl.replace(
        //   "public/images/",
        //   "images/"
        // )}`}
        mobile_banner={`https://api.biharsports.org/${commingData[0]?.mobileBannerUrl.replace(
          "public/images/",
          "images/"
        )}`}
      />
      <GridItem
        paddingBottom={"30px"}
        paddingTop={"10px"}
        bgGradient="linear(to-b, blue.700, white)"
        // bgGradient="linear-gradient(to b, blue, black)"
        color={"black"}
      >
        <h1>{commingData[0]?.aboutText}</h1>
      </GridItem>

      <SimpleGrid
        column={1}
        padding={"14px"}
        paddingLeft={"20%"}
        paddingRight={"20%"}
        // bg={"black"}
      >
        {commingData[0]?.OrgtnalSruItem?.map((elem, index) => (
          <>
            {!elem?.isDeleted ? (
              <GridItem
                border={"1px solid lightblue"}
                borderRadius={"8px"}
                alignItems={"center"}
                color={"black"}
                fontFamily={'"Poppins", sans-serif'} // Use the "Poppins" font
                paddingTop={"1%"}
                paddingBottom={"1%"}
                _hover={{
                  bg: "gray",
                  color: "white",
                }}
                fontSize={"2rem"}
              >
                {elem.aboutText}
              </GridItem>
            ) : (
              ""
            )}

            {
              (index =
                index + 1 && index ? (
                  <GridItem
                    paddingLeft={"50%"}
                    paddingTop={".5%"}
                    paddingBottom={".5%"}
                    color={"white"}
                  >
                    <img src="img/gg1.png" style={{ width: "13px" }} />
                  </GridItem>
                ) : (
                  ""
                ))
            }
          </>
        ))}
      </SimpleGrid>
    </div>
  );
};
export default OrganizationalStru;
