import React, { useEffect, useState } from "react";
import football from "../../assets/images/football.gif";
import archery from "../../assets/images/archery.gif";
import cycling from "../../assets/images/Cycling.gif";
import volleyBall from "../../assets/images/volleyBall.gif";
import weightLifting from "../../assets/images/weightLifting.gif";
import Styles from "../../styles/PrioritySports.module.css";

import {
  Container,
  Flex,
  Grid,
  GridItem,
  Link,
  SimpleGrid,
  border,
  useStatStyles,
} from "@chakra-ui/react";
import api from "../../utils/ApiMethod";
import axios from "axios";
import {
  Box,
  Center,
  useColorModeValue,
  Heading,
  Text,
  Stack,
  Image,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";

const IMAGE =
  "https://images.unsplash.com/photo-1518051870910-a46e30d9db16?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80";

const PrioritySports = () => {
  const [sportsData, setSportsData] = useState([]);

  const handleFetchData = async () => {
    try {
      // const data = await api.fetchData("/prioritysports");
      const data = await api.fetchData("https://api.biharsports.org/api/prioritysport");

      setSportsData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const bg = useColorModeValue("red.500", "red.200");

  return (
    <div>
      {/* Priority-Sports-of-Bihar-desktop */}

      <Breadcrumb
        pageName="Priority Sports of Bihar"
        pageTitle="Priority Sports of Bihar"
        id="#priority-sport"
        // img_url={`https://api.biharsports.org/${sportsData[0]?.bannerUrl.replace(
        //   "public/images/",
        //   "images/"
        // )}`}

        img_url={`https://api.biharsports.org/${sportsData[0]?.bannerUrl.replace(
          "public/images/",
          "images/"
        )}`}
        mobile_banner={`https://api.biharsports.org/${sportsData[0]?.mobileBannerUrl.replace(
          "public/images/",
          "images/"
        )}`}
      // mobile_banner={`https://api.biharsports.org/${sportsData[0]?.mobileBannerUrl.replace(
      //   "public/images/",
      //   "images/"
      // )}`}
      />
      <div
        style={{
          background: "linear-gradient(to bottom, #ffffff, #87CEFA)", // White to blue gradient
        }}
      >


        {/*--------------------------------------  first image start----------------------------  */}
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          pt={"30px"}
          boxShadow="dark-sm"
          fontSize={["8", "16"]}
          // letterSpacing="wide"
          color="teal.800"
          px={[4, 10, 20]}
          fontFamily="'Oswald', sans-serif;"
          textAlign={"justify"}
          // style={border}
          style={{
            background: "linear-gradient(to bottom, #4fa94f, white)", // White to blue gradient
          }}
        >
          <h4
            style={{
              color: "black",
              fontWeight: "800",
              fontSize: "18px",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            {sportsData[0]?.aboutText}
          </h4>
          {sportsData[0]?.content}

        </Text>


        <div
          style={{ backgroundColor: "gray", height: "2px", marginTop: "10px" }}
        ></div>
        <SimpleGrid
          columns={[1, 1, 1, 1]}
          spacing="5px"
          mt="40px"
          pb={"40px"}
          alignItems={"center"}
        >
          {/* <Box
            p={0}
            display={{ md: "flex" }}
            h="70vh"
            width="100%"
            px={[4, 10, 20]}
            py={[4, 10, 20]}
          > */}

          {sportsData[0]?.PrioritySport
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by createdAt in descending order

            .map((elem, i) => (
              <>
                <Box className={Styles.container} alignItems={"center"} key={i}>
                  <Box className={Styles.box30}>
                    <Image
                      boxSize="250px"
                      w="80%"
                      src={`https://api.biharsports.org/${elem?.image.replace(
                        "public/images/",
                        "images/"
                      )}`}
                      alt="Dan Abramov"
                    />
                  </Box>
                  <Box
                    className={Styles.box70}
                    fontWeight="bold"
                    // textTransform="uppercase"
                    fontSize={["8", "16"]}
                    letterSpacing="wide"
                    color="teal.800"
                    pt={"3px"}
                  >
                    <h1 style={{ paddingBottom: "3rem" }}>{elem?.sportName}</h1>
                    <p>{elem?.sportDetails} </p>
                  </Box>
                </Box>
                <div className={Styles.hotizontal}></div>
              </>
            ))}


          {/* </Box> */}

          {/*--------------------------------------  first image end----------------------------  */}
        </SimpleGrid>
      </div>
    </div>
  );
};
export default PrioritySports;
