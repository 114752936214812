import React, { useState } from "react";

const HeaderTop = () => {
  // const [hash, setHash] = useState("");

  // const handleLinkClick = (event) => {
  //   event.preventDefault();

  //   const targetHash = event.target.hash;
  //   const targetElement = document.querySelector(targetHash);

  //   if (targetElement) {
  //     window.scrollTo({
  //       top: targetElement.offsetTop - 0,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  var manual_or_random = "manual"; //"manual" or "random"
  var randomsetting = "3 days"; //"eachtime", "sessiononly", or "x days (replace x with desired integer)". Only applicable if mode is random.

  //////No need to edit beyond here//////////////

  function getCookie(Name) {
    var re = new RegExp(Name + "=[^;]+", "i"); //construct RE to search for target name/value pair
    if (document.cookie.match(re))
      //if cookie found
      return document.cookie.match(re)[0].split("=")[1]; //return its value
    return null;
  }

  function setCookie(name, value, days) {
    var expireDate = new Date();
    //set "expstring" to either future or past date, to set or delete cookie, respectively
    var expstring =
      typeof days != "undefined"
        ? expireDate.setDate(expireDate.getDate() + parseInt(days))
        : expireDate.setDate(expireDate.getDate() - 5);
    document.cookie =
      name + "=" + value + "; expires=" + expireDate.toGMTString() + "; path=/";
  }

  function deleteCookie(name) {
    setCookie(name, "moot");
  }

  function setStylesheet(title, randomize) {
    //Main stylesheet switcher function. Second parameter if defined causes a random alternate stylesheet (including none) to be enabled
    var i,
      cacheobj,
      altsheets = [""];
    for (i = 0; (cacheobj = document.getElementsByTagName("link")[i]); i++) {
      if (
        cacheobj.getAttribute("rel").toLowerCase() == "alternate stylesheet" &&
        cacheobj.getAttribute("title")
      ) {
        //if this is an alternate stylesheet with title
        cacheobj.disabled = true;
        altsheets.push(cacheobj); //store reference to alt stylesheets inside array
        if (cacheobj.getAttribute("title") == title)
          //enable alternate stylesheet with title that matches parameter
          cacheobj.disabled = false; //enable chosen style sheet
      }
    }
    if (typeof randomize != "undefined") {
      //if second paramter is defined, randomly enable an alt style sheet (includes non)
      var randomnumber = Math.floor(Math.random() * altsheets.length);
      altsheets[randomnumber].disabled = false;
    }
    return typeof randomize != "undefined" && altsheets[randomnumber] != ""
      ? altsheets[randomnumber].getAttribute("title")
      : ""; //if in "random" mode, return "title" of randomly enabled alt stylesheet
  }

  function chooseStyle(styletitle, days) {
    //Interface function to switch style sheets plus save "title" attr of selected stylesheet to cookie
    if (document.getElementById) {
      setStylesheet(styletitle);
      setCookie("mysheet", styletitle, days);
    }
  }

  /*----------font size change script--------------*/
  //Add font size
  var min = 13;
  var max = 19;
  var reset = 15;

  function increaseFontSize() {
    var p = document.getElementsByTagName("body");
    for (let i = 0; i < p.length; i++) {
      if (p[i].style.fontSize) {
        var s = parseInt(p[i].style.fontSize.replace("px", ""));
      } else {
        var s = 15;
      }
      if (s != max) {
        s += 1;
      }
      p[i].style.fontSize = s + "px";
    }
  }

  function decreaseFontSize() {
    var p = document.getElementsByTagName("body");
    for (let i = 0; i < p.length; i++) {
      if (p[i].style.fontSize) {
        var s = parseInt(p[i].style.fontSize.replace("px", ""));
      } else {
        var s = 15;
      }
      if (s != min) {
        s -= 1;
      }
      p[i].style.fontSize = s + "px";
    }
  }

  function reSetFontSize() {
    var p = document.getElementsByTagName("body");
    for (let i = 0; i < p.length; i++) {
      if (p[i].style.fontSize) {
        var s = parseInt(p[i].style.fontSize.replace("px", ""));
      } else {
        var s = 15;
      }
      if (s != reset) {
        s = 15;
      }
      p[i].style.fontSize = s + "px";
    }
  }

  return (
    <>
      <div className="header-toparea">
        <div className="header-topbar" style={{ backgroundColor: "#0c2545" }}>
          <div className=" d-flex">
            <ul className=" list-unstyled list-inline pull-left topLink top-right-link">
              <li>
                <div className="theme__apply__section">
                  <div className="apply__section">
                    <ul className="contrast topLink1 top-right-link">
                      <li className="night">
                        <a
                          href="#"
                          onClick={() => chooseStyle("black", 60)}
                          className="blackbox"
                          title="Contrast View"
                        >
                          &nbsp;
                        </a>
                      </li>
                      <li className="day">
                        <a
                          href="#"
                          onClick={() => chooseStyle("none", 60)}
                          className="whitebox"
                          title="Normal View"
                        >
                          &nbsp;
                        </a>
                      </li>
                    </ul>

                    {/* ---------------------------------------------------- ends the mode ------------------------------------------------------ */}

                    {/* ---------------------------------------------------text increase and decrease mode start issue  */}
                    <ul className="topLink top-right-link topLink1">
                      <li className="textsize screenReader" tabIndex="5">
                        <a
                          href="#"
                          onClick={increaseFontSize}
                          className="decrease-font screenreader"
                          title="Increase Font Size"
                          tabIndex="3"
                        >
                          A+
                        </a>
                        <a
                          href="#"
                          onClick={reSetFontSize}
                          className="reset-font"
                          title="Normal Font Size"
                        >
                          A
                        </a>

                        <a
                          href="#"
                          onClick={decreaseFontSize}
                          className="increase-font"
                          title="Decrease Font Size"
                        >
                          A-
                        </a>
                      </li>

                      <li
                        className="textsize tweeter screenReader"
                        tabIndex="7"
                      ></li>

                      <li
                        className="textsize instagram screenReader"
                        tabIndex="7"
                      ></li>

                      <li
                        className="textsize youtube screenReader"
                        tabIndex="7"
                      ></li>
                    </ul>
                  </div>
                </div>
              </li>

              <li className="screenReader link-dnone" tabIndex="0">
                <a href="/">Sitemap</a>
              </li>
              <li className="screenReader link-dnone" tabIndex="1">
                <a href="/" title="Download">
                  Screen Reader Access
                </a>
              </li>
              <li className="screenReader link-dnone" tabIndex="2">
                <a href="/query" title="Feedback">
                  Feedback
                </a>
              </li>
              <li className="screenReader link-dnone" tabIndex="3">
                <a href="/" title="RTI" target="_blank" rel="noreferrer">
                  RTI
                </a>
              </li>
              <li className="screenReader link-dnone" tabIndex="4">
                <a
                  href="#content"
                  className="skiptocontent"
                  title="Skip to Content"
                  // onClick={handleLinkClick}
                >
                  Go to Homepage
                </a>
              </li>
            </ul>

            <ul className="list-unstyled list-inline login-widget topLink top-right-link d-flex">
              <li className="ml-auto dropdown position-relative">
                <a
                  className="dropdown-toggle bg-headcont position-absolute top-0 start-0"
                  data-toggle="dropdown"
                  href="#"
                >
                  Online service
                  <span className="caret"></span>
                </a>

                <ul className="dropdown-menu login service top-dropdown">
                  <li className="">
                    <a href="/" target="_blank">
                      Log in
                    </a>
                  </li>

                  {/* <li className="">
                    <a href="#" target="_blank">
                      खेल सम्मान समारोह के लिए ऑनलाइन आवदेन
                    </a>
                  </li> */}

                  <li className="">
                    <a href="/" target="_blank">
                      Application for Khelo India Center
                    </a>
                  </li>
                </ul>
              </li>

              <li className="dropdown "></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default HeaderTop;
