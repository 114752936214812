import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";
import TopHeadlineCard from "./TopHeadlineCard";

const cards = [
  {
    id: 3,
    title: "Chess Championship 2024",
    text: "Chess Championship is being organized by Bihar State Sports Authority from 5th February 2024 to 10th February 2024.",
    image: "img/Chessss.jpg",
    url: "#",
  },
  {
    id: 1,
    title: "67th National School Games 2023",
    text: "The 67th National School Games 2023 is being organized in Bihar by the School Games Federation of India in 05 sports disciplines – Athletics, Cricket, Sepak Takraw, Weightlifting and Football.",
    image: "img/sixty_seventh_sgfi.jpg",
    url: "/national-school-games",
  },
  {
    id: 2,
    title: "National Games - 2023",
    text: "The National Games is being organized from 25 October to 09 November 2023. Many legendary players who have made India proud on the world stage have participated in this competition. The first National Games was organized in the year 1924 in Lahore city of undivided India. While 36 different sports disciplines were included in the National Games in the year 2022, this time the number of sports disciplines has increased to 43.    ",
    image: "img/national-games.jpeg",
    url: "/nationalgames",
  },
];

const NewTopHeadline = () => {
  return (
    <div
      className="container"
      style={{
        borderRadius: "8px",
        marginTop: "15px",
        marginBottom: "5px",
      }}
    >
      <Tabs isManual variant="enclosed">
        <TabList>
        <Tab fontSize="2xl" as="b">
        Chess Championship 2024
          </Tab>
          <Tab fontSize="2xl" as="b">
            67th National School Games 2023
          </Tab>
          <Tab fontSize="2xl" as="b">
            National Games - 2023
          </Tab>
        </TabList>
        <TabPanels
          style={{
            borderLeft: "1px solid #cfd9de",
            borderRight: "1px solid #cfd9de",
            borderBottom: "1px solid #cfd9de",
            borderRadius: "0px 0px 5px 5px",
          }}
        >
          {/* initially mounted */}

          {cards.map((card, index) => (
            <TabPanel key={index}>
              <TopHeadlineCard card={card} />
            </TabPanel>
          ))}

          {/* <TabPanel>
            <p>one!</p>
          </TabPanel>
          initially not mounted
          <TabPanel>
            <p>two!</p>
          </TabPanel> */}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default NewTopHeadline;
